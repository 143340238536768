<template>
  <div id="index" ref="appRef">
    <div class="detailsMask" v-if="showDetailsMask" @click="showDetailsMask = false">
      <div class="detailsMask-content" @click.stop.prevent>
        <div class="detailsMask-imgBorder">
          <img :src="detailsMaskImg || getImgUrl" class="detailsMask-img">
        </div>
        <div class="detailsMask-text">
          <div
            style="color:rgba(197, 224, 255, 1);padding-left: 31px;padding-top: 48px;margin-bottom: 24px;font-weight: 700;">
            积水点名称：
          </div>
          <div class="oneText">
            {{ curPointData.name }}
          </div>
          <div
            style="color:rgba(197, 224, 255, 1);margin-top:55px;padding-left: 31px;padding-top: 6px;margin-bottom: 20px;font-weight: 700;">
            积水点位置：
          </div>
          <div class="twoText">
            {{ curPointData.address }}
          </div>
        </div>
        <div class="detailsMask-close" @click="showDetailsMask = false">
        </div>
      </div>
    </div>
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">

        <div class="d-flex jc-center">
          <div class="d-flex jc-center">
            <div class="title">
              <img src="../assets/img/jihsuidian_title.png" class="title-img" style="width: 486px;height: 68px;">
            </div>
          </div>
        </div>
        <div class="content-body">
          <div class="content-left">
            <!-- <div class="weather">
              <img :src="tqImg[tianqi.weather] || tianqi.weatherPic" style="width: 47px;height: 47px;margin-right: 12px;">
              <div style="font-size: 33px;display: flex;align-items: flex-start;">
                <span style="height: 100%;">{{ tianqi.temperature.trim() }} </span><span
                  style="font-size: 22px;vertical-align: text-top;height: 100%;"> ℃</span>
              </div>
            </div> -->
            <leftThree ref="charts3" v-if="showMap" :list="list" class="content-left-bottom"></leftThree>
            <leftOne @showMidPoint="showMidPoint" :speed="0.3" :curPointData="curPointData" ref="charts1"
              class="content-left-top">
            </leftOne>
          </div>
          <div class="content-mid">
            <middleMap @openMask="openMask" ref="charts7" v-if="showMap" :list="list" @changeListState="changeListState"
              @clickPoint="clickPoint"></middleMap>
          </div>
          <div class="content-right">
            <!-- <div class="content-time">
              <div class="content-time-curtime">
                {{ hours }}{{ minutes }}{{ seconds }}
              </div>
              <div class="content-time-curday">
                <div>
                  {{ intYear }}-{{ intMonth }}-{{ intDay }}
                </div>
                <div>
                  {{ weekday[week] }}
                </div>
              </div>
            </div> -->
            <rightOne ref="charts4" v-if="showMap" :curPointLog="curPointLog" :curPointData="curPointData"
              :showDetails="showDetails" :list="list" :curExtras="curExtras" :moreHeight="25" :speed="3"
              class="content-left-top">
            </rightOne>
            <rightTwo v-if="showMessage" :messageList="messageList" ref="charts5"></rightTwo>
            <!-- <rightTwo ref="charts5" class="content-left-bottom"></rightTwo> -->
            <!-- <rightThree ref="charts6" class="content-right-item"></rightThree> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'

import leftOne from '../components/waterloggingPoint/leftOne.vue';
import leftThree from '../components/waterloggingPoint/leftThree.vue';
import middleMap from '../components/waterloggingPoint/middleMap.vue';
import rightOne from '../components/waterloggingPoint/rightOne.vue';
import rightTwo from '../components/waterloggingPoint/rightTwo.vue';

//weather-icon
import qingTian from '../assets/icon/tqing.png'
import duoYun from '../assets/icon/tduoyun.png'
import yinTian from '../assets/icon/tyewanyintian.png'
import xiaoYu from '../assets/icon/txiaoyu.png'
import zhongYu from '../assets/icon/tzhongyu.png'
import daYu from '../assets/icon/tdayu.png'
import leiYu from '../assets/icon/tleiyu.png'
import baoYu from '../assets/icon/tdayu.png'
import xiaoXue from '../assets/icon/txiaoxue.png'
import zhongXue from '../assets/icon/tzhongxue.png'
import daXue from '../assets/icon/tdaxue.png'
import baoXue from '../assets/icon/tdaxue.png'
import yuJiaXue from '../assets/icon/tyujiaxue.png'
import yeWan from '../assets/icon/tyewan.png'

import processingNoImg from '../assets/img/processingNoImg.png'
import completeNoImg from '../assets/img/completeNoImg.png'
import waitNoImg from '../assets/img/waitNoImg.png'

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      intYear: '',
      intMonth: '',
      intDay: '',
      hours: '',
      minutes: '',
      seconds: '',
      week: '',
      decorationColor: ['#568aea', '#000000'],
      list: [],
      alllist: [],
      messageList: [],
      curTime: '',
      showDetails: false,
      showMap: false,
      showMessage: false,
      curPointData: {},
      curExtras: {},
      curPointLog: [],
      tianqi: '',
      tqImg: {
        '晴': qingTian,
        '多云': duoYun,
        '阴': yinTian,
        '雷雨': leiYu,
        '雨夹雪': yuJiaXue,
        '小雨': xiaoYu,
        '中雨': zhongYu,
        '大雨': daYu,
        '暴雪': baoYu,
        '大暴雨': baoYu,
        '小雪': xiaoXue,
        '中雪': zhongXue,
        '大雪': daXue,
        '暴雪': baoXue,
        '雨': xiaoYu,
        '雪': xiaoXue,
        // '阵雨': ,
        // '特大暴雨': ,
        // '阵雪': ,
        // '雷阵雨伴有冰雹': ,
        // '雾': ,
        // '冻雨': ,
        // '沙尘暴': ,
        // '暴雨到大暴雨': ,
        // '小到中雪': ,
        // '中到大雪': ,
        // '大到暴雪': ,
        // '浮尘': ,
        // '扬沙': ,
        // '强沙尘暴': ,
        // '霾': ,
        // '无数据':
      },
      showDetailsMask: false,
      processingNoImg,
      completeNoImg,
      waitNoImg,
      detailsMaskImg: ''
    }
  },
  components: {
    leftOne,
    leftThree,
    middleMap,
    rightOne,
    rightTwo
  },

  async mounted() {
    this.initWeather()
    this.initMessage()
    this.tick()
    this.timeFn()
    this.cancelLoading()
    // this.startWs()
    await this.initData()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    async initWeather() {
      const res = await this.$http.get('https://xjl.nicewifi.cn/mall/buyer/other/weather/now?adCode=320300')
      if (res.data.code == 0) {
        this.tianqi = res.data.data
      }
    },
    async initMessage() {
      const res = await this.$http.get('https://xjl.nicewifi.cn/gis/messageBoard/page?limit=999')
      if (res.data.code == 0) {
        this.showMessage = true
        this.messageList = res.data.data.records
      }
    },
    async initData() {
      const res = await this.$http.get('https://xjl.nicewifi.cn/gis/gisMapScreen/list?page=1&limit=999')
      if (res.data.code == 0) {
        let arrComplete = []
        let arrProcessing = []
        let arrWait = []
        this.showMap = true
        // 排序 
        res.data.data.list.forEach(item => {
          if (item.extras.status == 'COMPLETE') {
            arrComplete.push(item)
          } else if (item.extras.status == 'PROCESSING') {
            arrProcessing.push(item)
          } else {
            arrWait.push(item)
          }
        })
        this.alllist = [...arrComplete, ...arrProcessing, ...arrWait]
        this.list = this.alllist
      }
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    tick() {
      var today;
      var hours;
      var minutes;
      var seconds;
      today = new Date();
      var intYear = today.getFullYear();
      var intMonth = today.getMonth() + 1;
      var intDay = today.getDate();
      var intHours = today.getHours();
      var intMinutes = today.getMinutes();
      var intSeconds = today.getSeconds();
      var week = today.getDay();
      if (intHours == 0) {
        hours = "00:";
      }
      else if (intHours < 10) {
        hours = "0" + intHours + ":";
      } else {
        hours = intHours + ":";
      }
      if (intMinutes < 10) {
        minutes = "0" + intMinutes + ":";
      } else {
        minutes = intMinutes + ":";
      }
      if (intSeconds < 10) {
        seconds = "0" + intSeconds + " ";
      } else {
        seconds = intSeconds + " ";
      }
      this.intYear = intYear
      this.intMonth = intMonth
      this.intDay = intDay
      this.hours = hours
      this.minutes = minutes
      this.seconds = seconds
      this.week = week
      setTimeout(() => {
        this.tick()
      }, 1000);
    },
    startWs() {

      var instanceId = 'post-cn-zpr3kmdk901';//实例 ID，购买后从控制台获取
      var host = 'post-cn-zpr3kmdk901.mqtt.aliyuncs.com';// 设置当前用户的接入点域名，接入点获取方法请参考接入准备章节文档，先在控制台创建实例
      var port = 80;//WebSocket 协议服务端口，如果是走 HTTPS，设置443端口
      var topic = 'test';//需要操作的 Topic,第一级父级 topic 需要在控制台申请
      var useTLS = false;//是否走加密 HTTPS，如果走 HTTPS，设置为 true
      //账号 accesskey，从账号系统控制台获取
      //阿里云账号AccessKey拥有所有API的访问权限，建议您使用RAM用户进行API访问或日常运维。
      //强烈建议不要把AccessKey ID和AccessKey Secret保存到工程代码里，否则可能导致AccessKey泄露，威胁您账号下所有资源的安全。
      //可以把AccessKey ID和AccessKey Secret保存在环境变量。运行本代码示例之前，请先配置环境变量MQTT_AK_ENV和MQTT_SK_ENV
      //例如：export MQTT_AK_ENV=access_key_id
      //     export MQTT_SK_ENV=access_key_secret
      //需要将access_key_id替换为已准备好的AccessKey ID，access_key_secret替换为AccessKey Secret
      var accessKey = 'LTAI5tL8Dg71Rc5bwU1YmaGV';
      //账号的的 SecretKey，在阿里云控制台查看
      var secretKey = 'c5RZ1MDoWL7mct1jW4HsvBmxC6NSF3';
      var cleansession = true;
      var groupId = 'GID_test';//MQTT GroupID,创建实例后从 MQTT 控制台创建
      var clientId = groupId + '@@@' + Date.now();//GroupId@@@DeviceId，由控制台创建的 Group ID 和自己指定的 Device ID 组合构成
      var mqtt;
      var reconnectTimeout = 2000;
      var username = 'Signature|' + accessKey + '|' + instanceId;//username和 Password 签名模式下的设置方法，参考文档 https://help.aliyun.com/document_detail/48271.html?spm=a2c4g.11186623.6.553.217831c3BSFry7
      var password = CryptoJS.HmacSHA1(clientId, secretKey).toString(CryptoJS.enc.Base64);
      // var password = 'I3SfRBDqzAjcz2ENzSGdILyZ0qs='
      function MQTTconnect() {
        mqtt = new Paho.MQTT.Client(
          host,//MQTT 域名
          port,//WebSocket 端口，如果使用 HTTPS 加密则配置为443,否则配置80
          clientId//客户端 ClientId
        );
        var options = {
          timeout: 3,
          onSuccess: onConnect,
          mqttVersion: 4,
          cleanSession: cleansession,
          onFailure: function (message) {
            // console.log('onFailure', message);
            setTimeout(MQTTconnect, reconnectTimeout);
          }
        };
        mqtt.onConnectionLost = onConnectionLost;
        mqtt.onMessageArrived = onMessageArrived;
        if (username != null) {
          options.userName = username;
          options.password = password;
          options.useSSL = useTLS;//如果使用 HTTPS 加密则配置为 true
        }
        mqtt.connect(options);
      }

      function onConnect(message) {
        // console.log('onConnect')
        // Connection succeeded; subscribe to our topic
        mqtt.subscribe(topic, { qos: 0 });
        message = new Paho.MQTT.Message("Hello mqtt!!");//set body
        message.destinationName = topic;// set topic
        //发送 P2P 消息，topic 设置方式参考https://help.aliyun.com/document_detail/96176.html?spm=a2c4g.11186623.6.586.694f7cb4oookL7
        message = new Paho.MQTT.Message("Hello mqtt P2P Msg!!");//set body
        message.destinationName = topic + "/p2p/" + clientId;// set topic
        mqtt.send(message);
      }

      function onConnectionLost(response) {
        // console.log('onConnectionLost', response)
        setTimeout(MQTTconnect, reconnectTimeout);
      };

      function onMessageArrived(message) {
        var topic = message.destinationName;
        var payload = message.payloadString;
        // console.log("recv msg : " + topic + "   " + payload);
      };
      MQTTconnect();
    },
    changeListState(data) {
      this.showDetails = false
      this.$refs.charts1.$children[0].run()
      if (data) {
        if (data != 'WAIT') {
          this.list = this.alllist.filter(item => {
            return item.extras.status === data
          })
        } else {
          this.list = this.alllist.filter(item => {
            return item.extras.status != 'COMPLETE' && item.extras.status != 'PROCESSING'
          })
        }
      } else {
        this.list = this.alllist
      }
    },
    async clickPoint({ data, id, midFlag }, flag) {
      console.log(midFlag)
      flag = midFlag
      const res = await this.$http.get('https://xjl.nicewifi.cn/gis/map/maintain/getLogVOList?poiId=' + id)
      if (res.data.code == 0) {
        this.curPointData = data
        this.curPointLog = res.data.data.gisPoiMaintainLogVOS
        this.curExtras = res.data.data.gisMapPoiExtraVO
        this.$refs.charts1.stop(flag)
        this.showDetails = true
        if (flag) {
          this.$refs.charts7.showTooltipsById(id)
        }
      }
    },
    showMidPoint(data) {
      if (this.list.length != this.alllist.length) {
        this.list = this.alllist
      }
      this.$refs.charts7.btnListIndex = 0
      this.alllist.forEach(item => {
        if (data.gisMapScreenVO.id == item.id) {
          this.clickPoint({ data: item, id: item.id, midFlag: true }, true)
        }
      })
    },
    openMask(data) {
      if (data.extras.status == 'WAIT') {
        this.detailsMaskImg = waitNoImg
      } else if (data.extras.status == 'PROCESSING') {
        this.detailsMaskImg = processingNoImg
      } else {
        this.detailsMaskImg = data.extras.imgUrl
      }
      this.curPointData = data
      this.showDetailsMask = true
    },
    getImgUrl() {
      if (this.curPointData.status == 'COMPLETE') {
        return this.completeNoImg
      } else if (this.curPointData.status == 'PORCESSING') {
        return this.porcessingNoImg
      } else {
        return this.waitNoImg
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';


.content-body {
  width: 100%;
  // height: 100%;
  flex: 1;
  display: flex;
  padding: 0 40px;
  flex-direction: row;
}

.content-title {
  width: 100%;
  text-align: center;

  .title-img {
    // width: 500px;
    // height: 5.8vh;
    // object-fit: cover;
  }
}

.content-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: -0.5%;
  width: 385px;
  position: relative;

  .weather {
    width: 260px;
    height: 50px;
    position: absolute;
    left: 0;
    top: -20px;
    // background-color: red;
    display: flex;
    align-items: center;
  }

  .content-left-top {
    height: 66.6%;
  }

  .content-left-bottom {
    flex: 1;
  }
}

.content-mid {
  flex: 2;
  height: 100%;
  padding-top: 2.5%;
}

.content-right {
  width: 385px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: -0.5%;
  position: relative;

  .content-left-top {
    height: 66.6%
  }

  .content-left-bottom {
    flex: 1;
  }

  .content-time {
    width: 260px;
    height: 50px;
    position: absolute;
    right: 0;
    top: -20px;
    display: flex;

    .content-time-curtime {
      height: 50px;
      font-size: 33px;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }

    .content-time-curday {
      margin-left: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 17px;
      height: 50px;
      font-size: 13px;
      color: rgba(197, 197, 197, 1);
    }
  }

}

.detailsMask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .48);

  .detailsMask-content {
    width: 1158px;
    height: 475px;
    // box-shadow: 0px 0px 6px 0px rgba(44, 144, 255, 0.64);
    background-image: url(../assets/img/detailsBg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 36px 0 53px 38px;
    display: flex;
    flex-direction: row;


    .detailsMask-imgBorder {
      width: 520px;
      height: 386px;
      background-image: url(../assets/img/imgBorder.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 14px;

      .detailsMask-img {
        width: 100%;
        height: 100%;
      }
    }

    .detailsMask-text {
      flex: 1;
      font-size: 36px;
      margin-left: 80px;

      line-height: 42.1px;
    }

    .detailsMask-close {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 34px;
      right: 34px;
      background-image: url(../assets/img/icon_cloase_changtai.png);
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: pointer;
    }

    .detailsMask-close:hover {
      background-image: url(../assets/img/icon_cloase_xuazne.png);
      cursor: pointer;
    }
  }
}

.oneText {
  width: 465px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twoText {
  width: 465px;
  height: 2.4em;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //display 块级元素展示
  display: -webkit-box;
  //设置文本行数为2行
  -webkit-line-clamp: 2;
  //设置文本行数为2行
  line-clamp: 2;
  //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
  -webkit-box-orient: vertical;
}
</style>
