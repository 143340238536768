<!--  -->
<template>
  <div class="middleMap">
    <div id="middleMapCharts">

    </div>
    <div class="map-btn">
      <div v-for="(item, index) in btnList" class="map-btn-item" @click="changeState(index, item)"
        :style="{ backgroundImage: index == btnListIndex ? 'url(' + item.a + ')' : 'url(' + item.in + ')' }">
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: '8e96a63a67e57c410bd961e8ae6ae373'  //填写你的安全密钥
}


import xuzhouCenter from '../../assets/geo/xuzhou/xuzhouCenter.json'
import imgComplete from '../../assets/img/finish.png'
import imgWait from '../../assets/img/wait.png'
import imgProcessing from '../../assets/img/processing.png'
export default {
  name: "middleMap",
  components: {
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },

  },
  watch: {
    list: {
      handler(newVal) {
        if (newVal.length) {
          // this.getOptions()
          this.map.clearMap()
          if (this.btnListIndex == 0) {
            this.addMarks(false)

          } else {
            this.addMarks(true)

          }
        }
      }
    },
  },
  data() {
    return {
      imgComplete,
      imgWait,
      imgProcessing,
      option: null,
      myMiddleMapCharts: null,
      timer: null,
      btnList: [
        {
          state: '',
          a: 'https://xjl.static.nicewifi.cn/bigScreen/all-a.png',
          in: 'https://xjl.static.nicewifi.cn/bigScreen/all-in.png',
        },
        {
          state: 'COMPLETE',
          a: 'https://xjl.static.nicewifi.cn/bigScreen/finish-a.png',
          in: 'https://xjl.static.nicewifi.cn/bigScreen/finish-in.png',
        },
        {
          state: 'PROCESSING',
          a: 'https://xjl.static.nicewifi.cn/bigScreen/processing-a.png',
          in: 'https://xjl.static.nicewifi.cn/bigScreen/processing-in.png',
        },
        {
          state: 'WAIT',
          a: 'https://xjl.static.nicewifi.cn/bigScreen/wait-a.png',
          in: 'https://xjl.static.nicewifi.cn/bigScreen/wait-in.png',
        },
      ],
      btnListIndex: 0,
      isPause: false,
      AMap: null
    }
  },
  created() {
    this.initMap()

  },
  mounted() {

  },
  methods: {
    resize() {
      if (!this.myLeftOneCharts) return
      this.myLeftOneCharts.resize()
    },
    initMap() {
      // this.$nextTick(() => {
      // this.myMiddleMapCharts = this.$echarts.init(document.querySelector('#middleMapCharts'))
      // this.$echarts.registerMap('xuzhouCenter', xuzhouCenter);
      // this.getOptions()
      // this.startShow()
      // })
      let load = AMapLoader.load({
        key: "6061e75324d64394df5a5ab48596965e",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.AMap = AMap;
        this.map = new AMap.Map("middleMapCharts", {  //设置地图容器id
          viewMode: "2D",    //是否为3D地图模式
          zoom: 13.8,           //初始化地图级别
          center: [117.197960, 34.253609], //初始化地图中心点位置
        });
        this.addMarks(false);
      }).catch(e => {
        console.log(e);
      })
    },
    addMarks(resizeFlag) {
      let that = this
      this.list.forEach(item => {
        let obj = {
          imageSize: new this.AMap.Size(27, 40),
        }
        if (item.extras.status == 'COMPLETE') {
          obj.image = 'https://xjl.static.nicewifi.cn/bigScreen/finish.png'
        } else if (item.extras.status == 'PROCESSING') {
          obj.image = 'https://xjl.static.nicewifi.cn/bigScreen/processing.png'
        } else {
          obj.image = 'https://xjl.static.nicewifi.cn/bigScreen/wait.png'
        }
        let marker = new this.AMap.Marker({
          icon: new this.AMap.Icon(obj),
          position: [+item.location.lon, +item.location.lat],
          offset: new this.AMap.Pixel(-5, -5),
          clickable: true
        });
        marker.setMap(this.map);
        marker.on('click', function (e) {
          that.$emit('openMask', item)
        });
      })
      if (resizeFlag) {
        this.map.setFitView()
      } else {
        this.map.setZoomAndCenter(13.8, [117.197960, 34.253609])
      }
    },
    startShow() {
      let that = this
      var index = 0; //播放所在下标
      this.timer = setInterval(function () {
        that.myMiddleMapCharts.dispatchAction({
          type: 'showTip',
          seriesIndex: 1,
          dataIndex: index
        });
        index++;
        if (index >= that.list.length) {
          index = 0;
        }
      }, 1800)
      this.myMiddleMapCharts.on('mouseover', function (params) {
        clearInterval(that.timer);
        that.myMiddleMapCharts.dispatchAction({
          type: 'showTip',
          seriesIndex: 1,
          dataIndex: params.dataIndex,
        });
      });
      this.myMiddleMapCharts.on('mouseout', function (params) {
        that.timer && clearInterval(that.timer);
        that.timer = setInterval(function () {
          that.myMiddleMapCharts.dispatchAction({
            type: 'showTip',
            seriesIndex: 1,
            dataIndex: index
          });
          index++;
          if (index >= that.list.length) {
            index = 0;
          }
        }, 1800);
      });
    },
    changeState(index, item) {
      debounce(this.send(index, item), 2000)

      function debounce(func, delay) {
        let timer; // 定义计时器变量

        return function () {
          clearTimeout(timer); // 清除之前设置的计时器

          timer = setTimeout(() => {
            func.apply(this, arguments); // 在指定的delay后调用传入的函数
          }, delay);
        };
      }

    },
    send(index, item) {
      this.btnListIndex = index
      this.$emit('changeListState', item.state)
    },
    getOptions() {
      function getStatusHtml(data, item) {
        if (data.status) {
          if (data.status == 'PROCESSING') {
            return `<div style='width:221px;height:67px;background-image:url(https://xjl.static.nicewifi.cn/bigScreen/info.png);
            background-size:221px 67px;font-size:12px;display:flex;flex-direction:column;justify-content: space-around;line-height:6px;padding:4px 0;
                  margin-top:12px;transform: translateX(-25px);'>
                    <span style='padding-left:35px'> <span style='margin-right:40px;color:rgba(255, 200, 96, 1);font-weight:700;'>正在治理</span></span>
                    <span style='padding-left:23px'>积水点名称:${item.data.name}</span>
                  </div>
            `
          } else if (data.status == 'COMPLETE') {
            return `<div style='width:221px;height:67px;background-image:url(https://xjl.static.nicewifi.cn/bigScreen/info.png);
            background-size:221px 67px;font-size:12px;display:flex;flex-direction:column;justify-content: space-around;line-height:6px;padding:4px 0;
                  margin-top:12px;transform: translateX(-25px);'>
                    <span style='padding-left:35px'> <span style='margin-right:40px;color:#61FF86;font-weight:700;'>治理完毕</span></span>
                    <span style='padding-left:23px'><span style='color:rgba(202, 228, 255, 1)'>积水点名称</span>:${item.data.name}</span>
                  </div>
            `
          } else {
            return `<div style='width:221px;height:67px;background-image:url(https://xjl.static.nicewifi.cn/bigScreen/info.png);
            background-size:221px 67px;font-size:12px;display:flex;flex-direction:column;justify-content: space-around;line-height:6px;padding:4px 0;
                  margin-top:12px;transform: translateX(-25px);'>
                  <span style='padding-left:35px'> <span style='margin-right:40px;color:rgba(255, 92, 1, 1);font-weight:700;'>尚未治理</span></span>
                    <span style='padding-left:23px'><span style='color:rgba(202, 228, 255, 1)'>积水点名称</span>:${item.data.name}</span>
                  </div>
            `
          }
        } else {
          return `<div style='width:221px;height:67px;background-image:url(https://xjl.static.nicewifi.cn/bigScreen/info.png);
                  background-size:221px 67px;font-size:12px;display:flex;flex-direction:column;justify-content: space-around;line-height:6px;padding:4px 0;
                  margin-top:12px;transform: translateX(-25px);'>
                  <span style='padding-left:35px'> <span style='margin-right:40px;color:rgba(255, 92, 1, 1);font-weight:700;'>尚未治理</span></span>
                    <span style='padding-left:23px'><span style='color:rgba(202, 228, 255, 1)'>积水点名称</span>:${item.data.name}</span>
                  </div>
            `
        }
      }
      function getCerame(params) {
        if (params.data.typeThird == "990103") {
          return `<img src='https://xjl.static.nicewifi.cn/bigScreen/%E6%91%84%E5%83%8F%E6%9C%BA%402x.png'
          style='width:20px;height:16px;position:absolute;right:10px;top:-40%'} />`
        } else {
          return ''
        }
      }

      function getStatus(data) {
        if (data.status) {
          if (data.status == 'PROCESSING') {
            return '治理中'
          } else if (data.status == 'COMPLETE') {
            return '治理完成'
          } else {
            return '待治理'
          }
        } else {
          return '待治理'
        }
      }
      // 如果有定时器 先清理定时

      clearInterval(this.timer);
      this.timer = null;

      var markPointData = []
      this.list.forEach(item => {
        let obj = {
          name: item.name,
          address: item.address,
          symbolSize: [27, 40],
          coord: [item.location.lon, item.location.lat],
          value: [item.location.lon, item.location.lat],
          label: {
            show: false,
            formatter: (name) => {
              return ''
            }
          },
          ...item
        }
        if (item.extras.status == 'COMPLETE') {
          obj.symbol = 'image://https://xjl.static.nicewifi.cn/bigScreen/finish.png'
        } else if (item.extras.status == 'PROCESSING') {
          obj.symbol = 'image://https://xjl.static.nicewifi.cn/bigScreen/processing.png'
        } else {
          obj.symbol = 'image://https://xjl.static.nicewifi.cn/bigScreen/wait.png'
        }
        markPointData.push(obj)
      })
      this.option = {
        tooltip: {
          trigger: 'item',
          show: true,
          // showContent: false,
          enterable: false,
          textStyle: {
            fontSize: 20,
            color: '#fff'
          },
          position: function (point, params, dom, rect, size) {
            if (params.seriesType == "map") return ''
            // console.log(params, dom, rect, size)
            // return [point[0] + 28, point[1] - 110];
            if (params.data && params.data.operator && params.data.operator.id) {
              return [rect.x + 32, rect.y - 100];
            } else {
              return [rect.x + 32, rect.y - 50];
            }
          },
          backgroundColor: 'transparent',
          borderWidth: '0',
          className: 'noShadow',
          extraCssText: 'box-shadow: unset;',
          formatter: function (params) {
            if (params.seriesType == "map") return ''
            if (params.data.operator && params.data.operator.name) {
              // 说明这是存在观察员的点位
              return `
                <div>
                  <div style='width:65px;height:65px;border-radius:50%;border: 1px solid rgba(45, 179, 255, 1);
                  position:absolute;top:-20px;left:-10px;background-image:url(${params.data.operator.avatar.split('?')[0]});background-size:65px 65px;'>
                  </div>
                  <div style='width:150px;height:16px;background-image:url(https://xjl.static.nicewifi.cn/bigScreen/jishuidian_name_bg.png);
                  background-size:100%;position:absolute;top:-10px;left:55px;'>
                  <p style='padding-left:8px;font-size: 14px;font-weight: 700;line-height:16px;position:absolute;top:-40%;left:0'>观察员:${params.data.operator.name}</p>
                  ${getCerame(params)}
                  </div>
                  <div style='max-width:450px;min-width: 209px;height:43px;background: linear-gradient(90deg, #1743E0 0%, rgba(49,92,192,0.85) 60%, rgba(66,109,171,0) 100%);
                  border-bottom-left-radius:6px;margin-left:15px;padding-left:40px;display:flex;flex-direction:column;justify-content: space-around;font-size:12px;
                  line-height:0;'>
                    <span >积水点:${params.data.name}</span>
                    <span >电话:${params.data.operator.phone}</span> 
                  </div>
                  ${getStatusHtml(params.data.extras, params)}
                </div>
                    `
            } else {
              return `
              ${getStatusHtml(params.data.extras, params)}
                    `
            }
          }
        },
        geo: [
          {
            map: 'xuzhouCenter',
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: '#fff'
                }
              },
              formatter: (params) => {
                console.log(params)
              }
            },
            itemStyle: {
              normal: {
                borderColor: 'rgba(102, 164, 255, 1)',
                borderWidth: 3,
                // areaColor: '#12235c'
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 1, color: 'rgba(90, 166, 255, 1)' },
                    // { offset: 0.5, color: 'rgba(255, 96, 0, 1)' },
                    { offset: 0, color: 'rgba(90, 166, 255, 0.22)' },
                  ]
                ),
                // shadowBlur: {
                //   shadowColor: '#102a74',
                //   shadowBlur: 20
                // },
                // shadowColor: '#102a74',
                // shadowOffsetX: -4,
                // shadowOffsetY: 13,
                // z: -1,
                // zlevel: -1
              },
            },
            emphasis: {
              disabled: true
            },
            roam: true,
            zoom: 1, //6
            zlevel: 2,
            layoutSize: '98%',
            layoutCenter: ['50%', '44%'],
            animationDurationUpdate: 16,
            // silent: true
            // center: [117.182225, 34.262249],
          },
          {
            map: 'xuzhouCenter',
            animationDurationUpdate: 16,
            roam: true, // 是否允许缩放
            zoom: 1, // 默认显示级别
            layoutSize: '98%',
            layoutCenter: ['48.5%', '46.5%'],
            itemStyle: {
              // areaColor: '#005DDC',
              areaColor: '#102a74',
              borderColor: 'transparent',
              borderWidth: 1,
              // normal: {
              //   borderColor: 'rgba(102, 164, 255, 0)',
              //   borderWidth: 1,
              // }
            },
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: '#fff'
                }
              },
            },
            zlevel: 1,
            silent: true
          }

        ],
        dataZoom: {
          type: 'inside'
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '3%',
          top: '25%',
        },
        series: [
          // map
          {
            type: 'map',
            geoIndex: 0,
            map: '', // 使用
          },
          {
            "name": "jishuidian",
            "type": "scatter",
            "coordinateSystem": "geo",
            label: {
              show: false,
            },
            data: markPointData,
            zlevel: 3,
          }
        ]
      }
      this.myMiddleMapCharts.setOption(this.option)
      this.myMiddleMapCharts.off('click') // 重点代码 避免点击事件重复执行
      this.myMiddleMapCharts.on('click', (params) => {
        if (params.componentSubType == 'scatter') {
          this.$emit('clickPoint', {
            id: params.data.id,
            data: params.data,
            midFlag: false
          })
        }
      });
      this.myMiddleMapCharts.on('georoam', (params) => {
        let option = this.myMiddleMapCharts.getOption();//获得option对象
        let len = option.geo.length;
        if (params.zoom != null) { //捕捉到缩放时
          for (var i = 0; i < len; i++) {
            option.geo[i].center = option.geo[0].center;
            option.geo[i].zoom = option.geo[0].zoom;
          }
        } else {//捕捉到拖曳时

          for (var i = 0; i < len; i++) {
            option.geo[i].center = option.geo[0].center;
          }
        }
        this.myMiddleMapCharts.setOption(option);//设置option
      })
    },
    showTooltipsById(id) {
      return
      this.isPause = true

      this.list.forEach((item, index) => {
        if (item.id == id) {
          clearInterval(this.timer);
          this.timer = null;
          console.log(item)
          this.$nextTick(() => {
            this.myMiddleMapCharts.dispatchAction({
              type: 'showTip',
              seriesIndex: 1,
              dataIndex: index
            });
          })
        }
      })
    }
  }
}

</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */

.middleMap::after {
  content: '';
  position: absolute;
  right: 35px;
  top: -50px;
  width: 135px;
  height: 900px;
  background-image: url(../../assets/img/img_banuan_you@2x.png);
  background-repeat: no-repeat;
  background-size: 135px 900px;
  // background-position-x: -260px;
  z-index: 999;
}

.middleMap::before {
  content: '';
  position: absolute;
  left: 35px;
  top: -50px;
  width: 135px;
  height: 900px;
  background-image: url(../../assets/img/img_banuan_zuo@2x.png);
  background-repeat: no-repeat;
  background-size: 135px 900px;
  z-index: 999;
}

.middleMap {
  position: relative;
  width: 100%;
  height: 900px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  // align-items: center;


  .middleMap-top {
    width: 100%;
    height: 200px;
    // background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .middleMap-top-item {
      width: 200px;
      height: 200px;
    }
  }

  .middleMap-visualMap {
    width: 300px;
    height: 50px;
    background-color: rgba(55, 55, 55, 0.35);
    position: absolute;
    bottom: 35px;
    left: 35px;
    z-index: 999;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;

    .middleMap-visualMap-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }

    .middleMap-visualMap-img {
      margin-top: 5px;
      width: 100%;
      height: 25px;
    }
  }
}

#middleMapCharts {
  width: 790px;
  height: 790px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 10px;
  z-index: 99999;
  // box-shadow: 1 1 8px rgba(0, 0, 0, 0.3);
  // box-shadow: inset 0px 0px 200px 1px rgba(9, 20, 40, 1);
  /* 添加模糊阴影效果 */
}

.map-btn {
  width: 80%;
  height: 131px;
  // background-color: red;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  z-index: 9999;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .map-btn-item {
    height: 100%;
    width: 94px;
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
  }
}

.noShadow {
  box-shadow: unset;
}
</style>