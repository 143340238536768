<!--  -->
<template>
  <div class="leftThree">
    <div class="content-title">
      积水点治理工程完成情况
    </div>
    <div id="leftThreeCharts">

    </div>
  </div>
</template>

<script>
export default {
  name: "leftThree",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftThreeCharts: null
    }
  },
  created() {
    if (!this.myLeftThreeCharts) {

      let process = 0
      let wait = 0
      let finish = 0
      let total = 0
      this.list.forEach(item => {
        if (item.extras.status == 'COMPLETE') {
          finish++
        } else if (item.extras.status == 'PROCESSING') {
          process++
        } else {
          wait++
        }
        total++
      })

      this.$nextTick(async () => {
        let data = [
          {
            value: finish, name: '治理完毕',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(41, 204, 121, 1)' },
                    // { offset: 0.5, color: 'rgba(255, 96, 0, 1)' },
                    { offset: 1, color: 'rgba(63, 196, 68, 1)' },
                  ]
                ),
              }
            }
          },
          {
            value: 5,
            itemStyle: {
              normal: {//颜色渐变
                color: 'rgba(255,255,255,0)'
              }
            },
            legend: {
              show: false
            }
          },
          {
            value: process, name: '正在施工',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(255, 243, 14, 1)' },
                    // { offset: 0.5, color: 'rgba(255, 96, 0, 1)' },
                    { offset: 1, color: 'rgba(226, 153, 43, 1)' },
                  ]
                ),
              }
            }
          },
          {
            value: 5,
            itemStyle: {
              normal: {//颜色渐变
                color: 'rgba(255,255,255,0)'
              }
            },
            legend: {
              show: false
            }
          },
          {
            value: wait, name: '正在推进',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(250, 70, 45, 1)' },
                    { offset: 1, color: 'rgba(255, 92, 0, 1)' },
                  ]
                ),
              }
            }
          },
          {
            value: 5,
            itemStyle: {
              normal: {//颜色渐变
                color: 'rgba(255,255,255,0)'
              }
            },
            legend: {
              show: false
            }
          },
        ]
        let legendData = [
          {
            value: finish, name: '治理完毕',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(41, 204, 121, 1)' },
                    // { offset: 0.5, color: 'rgba(255, 96, 0, 1)' },
                    { offset: 1, color: 'rgba(63, 196, 68, 1)' },
                  ]
                ),
              }
            }
          },
          {
            value: process, name: '正在施工',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(255, 243, 14, 1)' },
                    // { offset: 0.5, color: 'rgba(255, 96, 0, 1)' },
                    { offset: 1, color: 'rgba(226, 153, 43, 1)' },
                  ]
                ),
              }
            }
          },
          {
            value: wait, name: '正在推进',
            itemStyle: {
              normal: {//颜色渐变
                color: new this.$echarts.graphic.LinearGradient(
                  1, 1, 1, 0,  //(上-下 渐变)
                  // 1, 1, 0, 0,  //(左上-右下 渐变)
                  // 1, 0, 0, 0,  //(左-右 渐变)
                  // 0, 1, 1, 0,  //(右上-左下 渐变)
                  // 0, 1, 1, 1,  //(左-右 渐变)
                  // 1, 0, 1, 1,  //(上-下 渐变)
                  // 0, 0, 1, 0,  //(左-右 渐变)
                  // 0, 0, 0, 1,  //(上-下 渐变)
                  [
                    { offset: 0, color: 'rgba(250, 70, 45, 1)' },
                    { offset: 1, color: 'rgba(255, 92, 0, 1)' },
                  ]
                ),
              }
            }
          },
        ]
        this.option = {
          tooltip: {
            trigger: 'item'
          },
          title: {
            text: ['{a|' + total + '个}', '{b|积水点总数}'].join('\n'),
            top: 60,
            left: 79,
            textStyle: {
              rich: {
                a: {
                  color: '#fff',
                  fontSize: 22,
                  fontWeight: 'bold',
                  lineHeight: 33,
                  align: 'center',

                },
                b: {
                  color: '#fff',
                  fontSize: 12,
                  align: 'center',
                },
              }
            }
          },
          legend: {
            top: 40,
            right: 23,
            orient: 'vertical',
            // icon: 'circle',
            height: '100%',
            itemWidth: 12,
            itemHeight: 12,
            itemGap: 30,
            textStyle: {
              color: '#fff'
            },
            formatter: function (params) {
              const obj = data.find(item => item.name == params)
              return `${obj.name.padEnd(4, ' ')} ${(obj.value + '').padStart(4, '  ')}个 ${(obj.value * 100 / total).toFixed(1).padStart(6, ' ')}% `
            },
            data: legendData
          },
          series: [
            {
              name: '完成情况',
              type: 'pie',
              radius: ['70%', '89%'],
              center: ['30%', '55%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              data: data
            },
            //刻度尺
            {
              right: '40%',
              // name: "白色圈刻度",
              type: "gauge",
              radius: "89%",
              center: ['30%', '55%'],
              startAngle: 225, //刻度起始
              endAngle: -134.8, //刻度结束
              axisTick: {
                show: true,
                lineStyle: {
                  width: 2,
                  color: 'rgba(1,244,255, 0.3)'
                }
              },
              axisLabel: {
                show: false,
                color: 'rgba(1,244,255, 0.3)',
                fontSize: 12,
                show: false
              }, //刻度节点文字颜色
              pointer: {
                show: false
              },
              splitLine: {
                show: false,
                color: 'rgba(1,244,255, 0.3)'

              },
              axisLine: {
                show: false,
                lineStyle: {
                  opacity: 0
                }
              },
              detail: {
                show: false
              },
              data: [{
                value: 0,
                name: ""
              }]
            },
          ]
        };
        this.myLeftThreeCharts = this.$echarts.init(document.querySelector('#leftThreeCharts'))
        this.myLeftThreeCharts.setOption(this.option)
      })
    }
  },
  mounted() {

  },
  methods: {
    resize() {
      this.myLeftThreeCharts.resize()
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftThree {
  width: 385px;
  display: flex;
  flex-direction: column;
}

#leftThreeCharts {
  width: 385px;
  height: 175px;
  background: url("../../assets/img/charts_bg.png");
  background-size: cover;
  padding-bottom: 4%;
}

.content-title {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;

}
</style>