<!--  -->
<template>
  <div class="rightTwo">
    <div class="content-title">
      百姓留言板
    </div>
    <div id="rightTwoCharts">
      <list-scroll class="box" :speed="0.2">
        <div class="list">
          <div v-for="item in messageList" class="messageList">
            <span style="color: rgba(197, 224, 255, 1);"> {{ item.name }}：</span>{{ item.message }}
          </div>
        </div>

      </list-scroll>
    </div>
  </div>
</template>

<script>

import ListScroll from "../../commonComponents/listRight.vue";


export default {
  name: "rightTwo",
  components: {
    ListScroll
  },
  props: {
    messageList: {
      type: Array,
      default: []
    },
  },
  watch: {

  },
  computed: {

  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightTwo {
  display: flex;
  flex-direction: column;
}

#rightTwoCharts {
  width: 385px;
  height: 550px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 27px;
  box-sizing: border-box;
  // background: linear-gradient(161deg, rgba(21, 119, 251, 0.37) 0%, rgba(9, 21, 43, 0) 100%);
  background: url("../../assets/img/charts_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.content-title {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}

.box {
  width: 385px;
  height: 496px;
  overflow: hidden;
  padding: 0 12px;
  margin-bottom: 10px;
  // box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
  position: relative;
}


.list {
  width: 100%;

  .messageList {
    width: 100%;
    margin: 7px 0;
    display: inline-block;
    font-size: 12px;
    line-height: 1.5em;
  }

}
</style>
