<!--  -->
<template>
  <div class="rightOne">
    <div class="content-title">
      积水点列表
    </div>
    <div id="rightOneCharts">
      <list-scroll class="box" :speed="0.3" v-if="true">
        <div class="list">
          <div class="content" v-for="item in list"
            :style="{ background: item.extras.status === 'COMPLETE' ? 'linear-gradient(148deg, rgba(51,201,98,0.16) 0%, rgba(9,21,43,0) 100%)' : item.extras.status === 'PROCESSING' ? 'linear-gradient(148deg, rgba(251,231,19,0.09) 0%, rgba(9,21,43,0) 100%)' : 'linear-gradient(148deg, rgba(255,92,1,0.09) 0%, rgba(9,21,43,0) 100%)' }">
            <div class="content-left">
              <img v-if="item.extras.status === 'COMPLETE'" src="../../assets/icon/icon_sanjiao_lv@2x.png"
                style="width: 100%;height: 100%;" />
              <img v-else-if="item.extras.status === 'PROCESSING'" src="../../assets/icon/icon_sanjiao_huang@2x.png"
                style="width: 100%;height: 100%;" />
              <img v-else src="../../assets/icon/icon_sanjiao_hong@2x.png" style="width: 100%;height: 100%;" />
            </div>
            <div class="list-right">
              <div class="list-right-info">
                <div class="list-right-info-text">
                  <img src="../../assets/icon/icon1.png" style="width: 13px;height: 16px;marginRight: 7px;">
                  <div>观察员姓名：</div>
                  <div class="list-right-info-text-nowrap">{{ item.extras && item.extras.name || '暂无观察员' }}</div>
                </div>
                <div class="list-right-info-text">
                  <img src="../../assets/icon/icon2.png" style="width: 13px;height: 16px;marginRight: 7px;">
                  <div>积水点名称：</div>
                  <div class="list-right-info-text-nowrap">{{ item.name }}</div>
                </div>
                <div class="list-right-info-text">
                  <img src="../../assets/icon/icon3.png" style="width: 13px;height: 16px;marginRight: 7px;">
                  <div>积水点位置：</div>
                  <div class="list-right-info-text-nowrap">{{ item.address }}</div>
                </div>
                <div class="list-right-info-text">
                  <img src="../../assets/icon/icon4.png" style="width: 13px;height: 16px;marginRight: 7px;">
                  <div>积水点状态：</div>
                  <div class="list-right-info-text-nowrap" v-if="item.extras.status === 'COMPLETE'"
                    style="color: #31CA68;">
                    治理完毕</div>
                  <div class="list-right-info-text-nowrap" v-else-if="item.extras.status === 'PROCESSING'"
                    style="color: #FBE414;">正在施工</div>
                  <div class="list-right-info-text-nowrap" v-else style="color: #FF5C01;">正在推进</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </list-scroll>
      <div v-else style="display: flex;flex-direction: column;">
        <div class="charts-body">
          <div class="charts-left" v-if="curExtras.status === 'COMPLETE'">
            <img :src="curExtras.imgUrl || '../../assets/img/noVideo1.png'" style="width: 100%;height: 100%;" />
          </div>
          <div class="charts-right">
            <div class="charts-right-name">
              <div class="charts-right-name-top">
                <img src="../../assets/img/water.png" class="charts-right-name-img">
                <div class="charts-right-name-text">
                  积水点名称：
                </div>
              </div>
              <p class="charts-right-name-bottom" :style="{ marginTop: curExtras.status === 'COMPLETE' ? '0' : '4px' }">
                {{ curPointData.name || '暂无' }}
              </p>
            </div>
            <div class="charts-right-location">
              <div class="charts-right-location-top">
                <img src="../../assets/img/location.png" class="charts-right-location-img">
                <p class="charts-right-location-text">
                  积水点位置：
                </p>
              </div>
              <p class="charts-right-location-bottom"
                :style="{ marginTop: curExtras.status === 'COMPLETE' ? '0' : '4px' }">
                {{ curPointData.address || '暂无' }}
              </p>
            </div>
          </div>
        </div>
        <div class="charts-body-bottom">
          <div class="charts-body-bottom-title">
            点位信息
          </div>
          <div v-for="(item, index) in curPointLog">
            <div v-if="index == 0" class="charts-body-bottom-item">
              <div style="width: 70px;height: 105px;position: relative;">
                <img :src="getIcon(item)" style="width: 100%;height: 100%;">
                <img src="../../assets/icon/icon_jiantou@2x.png" v-if="(index + 1) !== curPointLog.length"
                  style="width: 37px;height: 61px;position: absolute;bottom: -21px;right: 8px;">
              </div>
              <div class="charts-body-bottom-item-right">
                <div class="charts-body-bottom-item-right-content" :style="{
                  background: item.auditStatus === 'COMPLETE' ?
                    'linear-gradient(90deg, rgba(28,202,54,0.56) 0%, rgba(9,21,43,0) 100%)'
                    : item.auditStatus === 'PROCESSING' ?
                      'linear-gradient(90deg, #FFC700 0%, rgba(9,21,43,0) 100%)'
                      : 'linear-gradient(90deg, #E55821 0%, rgba(9,21,43,0) 100%)'
                }">
                  <div style="display: flex;justify-content: space-between;">
                    <div style="font-size: 16px;font-weight: 700;color: #fff;">
                      {{ statusList[statusList.findIndex(i => i.statu == item.auditStatus)].text }}
                    </div>
                    <!-- <div style="font-size: 12px;" :style="{
                      color: item.auditStatus === 'COMPLETE' ?
                        'rgba(68, 210, 106, 0.56)'
                        : item.auditStatus === 'PROCESSING' ?
                          'rgba(255, 233, 153, 0.60)'
                          : 'rgba(255, 161, 124, 0.47)'
                    }">
                      {{ getText(item).time }} {{ item.time }}
                    </div> -->
                  </div>
                  <div style="color: #fff;font-size: 12px;" class="textEs">
                    点位: {{ item.projectName }}
                  </div>
                  <div style="color: #fff;font-size: 12px;" class="textEs" v-if="getText(item).show">
                    {{ getText(item).content }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="charts-body-bottom-item">
              <div style="width: 70px;height:105px;position: relative;">
                <img src="../../assets/icon/img_gongju_changtai@2x.png" style="width: 100%;height: 100%;">
                <img src="../../assets/icon/icon_jiantou@2x.png" v-if="(index + 1) !== curPointLog.length"
                  style="width: 37px;height: 61px;position: absolute;bottom: -21px;right: 8px;">
              </div>
              <div class="charts-body-bottom-item-right">
                <div class="charts-body-bottom-item-right-content">
                  <div style="display: flex;justify-content: space-between;">
                    <div style="font-size: 16px;font-weight: 700;color: #C5E0FF;">
                      {{ statusList[statusList.findIndex(i => i.statu == item.auditStatus)].text }}
                    </div>
                    <!-- <div style="font-size: 12px;color: #659EE2;">
                      {{ getText(item).time }} {{ item.time }}
                    </div> -->
                  </div>
                  <div style="color: #fff;font-size: 12px;" class="textEs">
                    点位: {{ item.projectName }}
                  </div>
                  <div style="color: #fff;font-size: 12px;" class="textEs" v-if="getText(item).show">
                    {{ getText(item).content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ListScroll from "../../commonComponents/listRight.vue";
import chushi from '../../assets/icon/img_gongju_bai@2x.png'
import weichuli from '../../assets/icon/img_gongju_hong@2x.png'
import chulizhong from '../../assets/icon/img_gongju_huang@2x.png'
import chuliwanbi from '../../assets/icon/img_gongju_lv@2x.png'
import otherPoint from '../../assets/icon/img_gongju_changtai@2x.png'


export default {
  name: "rightOne",
  components: {
    ListScroll
  },
  props: {
    list: {
      type: Array,
      default: []
    },
    curPointLog: {
      type: Array,
      default: []
    },
    curPointData: {
      type: Object,
    },
    curExtras: {
      type: Object,
    },

  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      statusList: [
        { statu: "COMPLETE", text: "治理完毕" },
        { statu: "PROCESSING", text: "正在治理" },
        { statu: "WAIT", text: "尚未治理" },
        { statu: "FEEDBACK", text: "已反馈相关部门" },
        { statu: "READY", text: "观察员已就位" },
      ],
      chushi,
      weichuli,
      chulizhong,
      chuliwanbi,
      otherPoint
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    resize() {
      return
    },
    getIcon(item) {
      if (item.auditStatus == 'COMPLETE') {
        return this.chuliwanbi
      } else if (item.auditStatus == 'PROCESSING') {
        return this.chulizhong
      } else if (item.auditStatus == 'WAIT') {
        return this.weichuli
      } else if (item.auditStatus == 'FEEDBACK') {
        return this.chushi
      } else {
        return this.otherPoint
      }
    },
    getText(data) {
      let obj = {
        show: false,
        time: '',
        content: ''
      }
      if (data.auditStatus == 'UNPROCESS') {
        obj.time = '计划处理'
        obj.show = true
        obj.content = '原因:' + (data.reason || '暂无')
      } else if (data.auditStatus == 'COMPLETE') {
        obj.time = '完成时间'
      } else if (data.auditStatus == 'PROCESSING') {
        obj.time = '预计时间'
      } else if (data.auditStatus == 'WAIT') {
        obj.time = '计划处理'
        obj.content = '原因:' + (data.reason || '暂无')
        obj.show = true
      } else if (data.auditStatus == 'FEEDBACK') {
        obj.time = '反馈时间'
        obj.content = '反馈单位:' + (data.reason || '暂无')
        obj.show = true
      } else if (data.auditStatus == 'READY') {
        obj.time = '就位时间'
      }
      return obj
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.rightOne {
  display: flex;
  flex-direction: column;
}

#rightOneCharts {
  width: 385px;
  height: 306px;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  .charts-body {
    width: 385px;
    height: 145px;
    padding: 0 10px 15px 10px;
    display: flex;
    flex-direction: row;



    .charts-left {
      width: 240px;
      height: 130px;
      background-image: url(../../assets/img/noVideo.png);
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 6px 7px;
    }


    .charts-right {
      flex: 1;
      margin-left: 7px;
      display: flex;
      flex-direction: column;

      .charts-right-name {
        background: linear-gradient(to bottom, rgba(21, 119, 251, 0.37), rgba(9, 21, 43, 0));
        height: 50%;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 5px;
        padding: 6px 4px;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;

        .charts-right-name-top {
          display: flex;
          align-items: center;

          .charts-right-name-img {
            width: 13px;
            height: 16px;
            margin-right: 8px;
          }

          .charts-right-name-text {
            font-size: 14px;
            font-weight: bold;
            color: #C5E0FF;
          }
        }

        .charts-right-name-bottom {
          height: 31px;
          font-size: 12px;
          color: #FFFFFF;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }


      }

      .charts-right-location {
        background: linear-gradient(to bottom, rgba(21, 119, 251, 0.37), rgba(9, 21, 43, 0));
        height: 50%;
        width: 100%;
        padding: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .charts-right-location-top {
          display: flex;
          align-items: center;

          .charts-right-location-img {
            width: 13px;
            height: 16px;
            margin-right: 8px;
          }

          .charts-right-location-text {
            height: 18px;
            font-size: 14px;
            font-weight: bold;
            color: #C5E0FF;
          }
        }

        .charts-right-location-bottom {
          font-size: 12px;
          color: #FFFFFF;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .charts-body-bottom {
    flex: 1;
    width: 100%;
    padding: 0 10px 15px 10px;
    min-height: 300px;

    .charts-body-bottom-line {
      width: 100%;
      height: 1px;
      margin-bottom: 2px;
      background-image: linear-gradient(to right, rgba(115, 248, 254, 0) 12%, #73F8FE 19%, rgba(115, 248, 254, 0) 50%);
      // box-sizing: border-box;
      // border: 2px solid;
      // border-image: linear-gradient(to right, rgba(115, 248, 254, 0) 12%, #73F8FE 19%, rgba(115, 248, 254, 0) 50%);
    }

    .charts-body-bottom-title {
      width: 100%;
      height: 25px;
      padding-left: 12.5px;
      background-image: url(../../assets/img/lab_xiao@2x.png);
      background-size: 100% 25px;
      background-repeat: no-repeat;
      font-size: 15px;
      color: '#fff';
      line-height: 25px;
      margin-bottom: 25px;
      font-weight: 700;
    }

    .charts-body-bottom-item {
      width: 100%;
      height: 119px;
      // background-color: red;
      margin-bottom: 2px;
      display: flex;
      flex-direction: row;
      position: relative;


      .charts-body-bottom-item-right {
        flex: 1;
        height: 100%;
        position: relative;

        // background-color: #000000;
        .charts-body-bottom-item-right-content {
          width: 303px;
          // height: 85px;
          background: linear-gradient(102deg, rgba(21, 119, 251, 0.37) 4%, rgba(9, 21, 43, 0) 101%);
          position: absolute;
          top: 0px;
          left: 3px;
          border-bottom-left-radius: 9px;
          border-top-left-radius: 9px;
          padding: 12px 2px 10px 9px;
        }
      }
    }
  }

}

.content-title {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}

.box {
  width: 385px;
  height: 306px;
  overflow: hidden;
  padding: 0 12px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.list {
  width: 100%;

  .content {
    width: 100%;
    height: 129px;
    display: flex;
    margin: 12px 0;
    align-items: center;
    position: relative;

    .list-right {
      flex: 1;
      height: 100px;
      width: 274px;
      padding-left: 26px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .list-right-info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .list-right-info-text {
          width: 100%;

          font-size: 12px;
          padding: 4px 0 4px 9px;
          display: flex;
          align-items: center;

          .list-right-info-text-nowrap {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }

    .content-left {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
    }
  }

}

.textEs {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
}
</style>
