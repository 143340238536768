<template>
  <div class="listScroll" :class="positionTpye" ref="boxL">
    <slot></slot>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "listScroll",
  created() { },
  mounted() {
    //在盒子内容高度小于可视高度时不滚动
    if (this.boxHeight < this.ele0.clientHeight) {
      this.start(this.height);
      this.setEvet();
    } else {
      this.isScroll = false;
    }
  },
  props: {
    speed: {
      default: 1,
      type: Number,
    },
    positionTpye: {

    },
    moreHeight: {
      default: 20,
    }
  },
  computed: {
    //第一个slot
    ele0() {
      return this.$refs.boxL.children[0];
    },
    //第二个slot
    ele1() {
      return this.$refs.boxL.children[1];
    },
    //盒子的可视高度
    boxHeight() {
      return this.$refs.boxL.clientHeight;
    },

  },
  data() {
    return {
      height: 0,
      isScroll: true,
    };
  },
  methods: {
    stop() {
      this.isScroll = false;
      window.addEventListener('wheel', this.addHeight)
    },
    addHeight(data) {
      this.height += data.wheelDeltaY > 0 ? -30 : 30
      this.start(this.height, false)
    },
    run() {
      if (this.isScroll) return
      this.$nextTick(() => {
        window.removeEventListener('wheel', this.addHeight)
        this.isScroll = true
        this.start(this.height)
      })
    },
    jump(data, flag) {
      if (flag) return
      this.height = data
      this.start(this.height, false)
    },
    //鼠标移入停止滚动 移出继续滚动
    setEvet() {
      this.$refs.boxL.onmouseenter = () => {
        this.isScroll = false;
        // this.height = 0;
      };
      this.$refs.boxL.onmouseleave = () => {
        this.isScroll = true;
        this.$nextTick(() => {
          window.removeEventListener('wheel', this.addHeight)
          this.start(this.height);
        });
      };
    },
    //滚动方法
    start(height, flag = true) {
      this.ele0.style = `transform:translateY(-${height}px);`;
      this.ele1.style = `height:${this.boxHeight}px;transform:translateY(-${height}px);overflow: hidden;`;
      if (height >= this.ele0.clientHeight + this.moreHeight) {
        this.height = 0;
      } else {
        this.height += this.speed;
      }
      if (!this.isScroll) return;
      if (flag) {
        window.requestAnimationFrame(() => {
          this.start(this.height);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.listScroll {
  overflow: hidden;
}

.hover {
  overflow-y: auto;
}

.hide {
  display: none;
}
</style>