<!--  -->
<template>
  <div class="leftOne">
    <div class="content-title">
      百姓观察员
    </div>
    <list-scroll :moreHeight="30" ref="leftOneList" class="boxL" :speed="speed" v-if="list.length != 0"
      :positionTpye="'left'">
      <div class="list">
        <div class="content" @click="showPoint(item, index)" v-for="(item, index) in list" :key="item.id"
          :id="item.gisMapScreenVO.id"
          :class="[curPointData && item.gisMapScreenVO.id == curPointData.id ? 'content isActive' : 'content']">
          <div class="list-left">
            <div class="list-left-avatar">
              <img :src="item.avatar.split('?')[0]" style="width: 100%;height: 100%;">
            </div>
          </div>
          <div class="list-right">
            <div class="list-right-name">
              <span style="color: #ccddfd;">观察员:</span>{{ item.name }}
            </div>
            <div class="list-right-info">
              <div class="list-right-info-text colorTel">
                电话:{{ item.phone }}
              </div>
              <div class="list-right-info-text colorName">
                工程名称:{{ item.gisMapScreenVO.name }}
              </div>
              <div class="list-right-info-text colorAddress">
                工程地址:{{ item.gisMapScreenVO.address }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </list-scroll>
  </div>
</template>

<script>
import ListScroll from "../../commonComponents/list.vue";
export default {
  name: "leftOne",
  components: {
    ListScroll
  },
  props: {
    speed: {
      type: Number,
      default: 0.5, // 默认速度为1000毫秒（1秒）
    },
    curPointData: {
      type: Object
    }
  },
  watch: {

  },
  computed: {

  },
  data() {
    return {
      option: null,
      myLeftOneCharts: null,
      list: [],
    }
  },
  created() {
    // 获取积水点观察员数据
    this.init()
  },
  mounted() {

  },
  methods: {
    resize() {
      return
    },
    init() {
      this.$http.get('https://xjl.nicewifi.cn/gis/clerkMemberPhone/list?page=1&limit=999').then(res => {
        this.list = res.data.data.list
      })
    },
    stop(dataFlag) {
      this.$nextTick(() => {
        let flag = false
        this.list.forEach((item, index) => {
          if (item.gisMapScreenVO && item.gisMapScreenVO.id == this.curPointData.id) {
            flag = true
            this.$refs.leftOneList.jump(index * 118, dataFlag)
            this.$refs.leftOneList.stop()
          }
        })
        if (!flag) {
          this.$refs.leftOneList.run()
        }
      })
    },
    showPoint(item, index) {
      // this.$emit('showMidPoint', item)
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.leftOne {
  width: 385px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-title {
  font-size: 20px;
  font-family: FZZ;
  width: 385px;
  height: 37.17px;
  color: #fff;
  font-style: italic;
  font-weight: 700;
  padding-left: 70px;
  background: url("../../assets/img/charts_title.png");
  background-size: 440px 100%;
  margin-bottom: 12px;
}

.boxL {
  width: 385px;
  height: 650px;
  overflow: hidden;
  padding: 0 12px;
  transform: translateX(0);
  z-index: 999;
}

.list {
  width: 100%;

  .content {
    width: 100%;
    height: 106px;
    display: flex;
    margin: 12px 0;

    .list-left {
      display: flex;
      flex-direction: column;

      .list-left-avatar {
        width: 87px;
        height: 100%;
      }

      .list-left-state {
        font-size: 12px;
        margin-top: 7px;
        text-align: center;
        color: #D7DCF4;
        background-image: linear-gradient(to bottom, rgba(21, 119, 251, 0.37), rgba(9, 21, 43, 0));
      }
    }

    .list-right {
      flex: 1;
      height: 100%;
      width: 274px;
      // padding-left: 13px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px 0;
      padding-left: 13px;
      margin-left: 13px;
      background: url(../../assets/img/Rectangle.png);
      background-repeat: no-repeat;
      background-size: 110%;
      background-position-y: -5px;

      .list-right-name {
        font-size: 14px;
        padding-top: 4px;
        font-weight: 700;
        color: #fff;
      }

      .list-right-info {
        width: 100%;

        .list-right-info-text {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 12px;
          padding: 2px 0 2px 0px;
        }
      }
    }
  }

}

// .colorTel {
//   background-image: linear-gradient(to right, rgba(21, 119, 251, 0.37), rgba(9, 21, 43, 0));
// }

// .colorName {
//   background-color: rgba(21, 119, 251, 0.30);
// }

// .colorAddress {
//   background-color: rgba(69, 148, 255, 0.16);
// }

.isActive {
  background: linear-gradient(180deg, rgba(69, 121, 255, 0.56) 0%, rgba(69, 121, 255, 0) 100%);
  border: 1px solid #16D5FF;
}
</style>